import { createContext } from "react";
import { useReducer } from "react";
import { initial_user, userReducer } from "./reducers/user.reducer";

export const UserContext = createContext(initial_user);

export const UserState = ({ children }: any) => {
  const [user, dispatch] = useReducer(userReducer, initial_user);

  return (
    <UserContext.Provider value={{ user, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
