import { useTranslation } from "react-i18next";
import { MdOutlineChecklist, MdOutlineSettings } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { Flex, LinkBox, Text } from "@chakra-ui/react";
import PlusButtonMenu from "../plus-button-menu";

function BottomNavigation() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigationElements = [
    {
      label: t("Lists"),
      link: "/",
      icon: <MdOutlineChecklist size={20} />,
    },
    {
      label: t("Settings"),
      link: "/settings",
      icon: <MdOutlineSettings size={20} />,
    },
  ];

  return (
    <Flex
      as="nav"
      w="100%"
      h="80px"
      pb="5px"
      zIndex={1000}
      bg="white"
      position="fixed"
      bottom="0"
    >
      <Flex>
        <PlusButtonMenu />
      </Flex>
      {navigationElements.map((el) => (
        <LinkBox
          flex="0 0 50%"
          width="50%"
          justifyContent="center"
          alignContent="center"
          key={el.label}
          display="flex"
        >
          <Link key={el.label} to={el.link}>
            <Flex
              w="100%"
              cursor="pointer"
              h="100%"
              minW="100px"
              justifyContent="center"
              alignContent="center"
              flexWrap="wrap"
              color={pathname === el.link ? "brand.500" : "gray.500"}
            >
              {el.icon}
              <Text
                w="100%"
                textAlign="center"
                fontSize="12px"
                fontWeight="500"
              >
                {el.label}
              </Text>
            </Flex>
          </Link>
        </LinkBox>
      ))}
    </Flex>
  );
}

export default BottomNavigation;
