import { AxiosResponse } from "axios";
import { carttieAPI } from "../axios";

const BASE_OTP_URL = "/otp/";
const START_OTP = `${BASE_OTP_URL}otp-start/`;
const END_OTP = `${BASE_OTP_URL}otp-accept/`;
const REFRESH_TOKEN = `${BASE_OTP_URL}token-refresh/`;

type StartOTPType = {
  /**
   * User email
   * @example test@example.com
   */
  email: string;
};

export const startOTP = async (data: StartOTPType) =>
  await carttieAPI.post(START_OTP, {
    ...data,
  });

type EndOTPType = {
  /**
   * OTP code
   * @example 511232
   */
  OTP: string;
  /**
   * User email
   * @example test@example.com
   */
  email: string;
};

type TokenResponse = {
  accessToken: string;
  refreshToken: string;
  full_name: string;
};

export const endOTP = async (
  data: EndOTPType
): Promise<AxiosResponse<TokenResponse>> =>
  await carttieAPI.post(END_OTP, {
    ...data,
  });

type RefreshAccessTokenResponse = {
  accessToken: string;
};

export const refreshAccessToken = async (
  refreshToken: string
): Promise<AxiosResponse<RefreshAccessTokenResponse>> =>
  await carttieAPI.post(REFRESH_TOKEN, { refreshToken });
