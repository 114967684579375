import { carttieAPI } from "./axios";
import { ProductsDTO, ProductsGET } from "./products.api.type";

const DEFAULT_PATH = "products";
const PRODUCT_BY_ID = `${DEFAULT_PATH}/id`;

export const getDefaultProducts = async () => {
  return await carttieAPI.get<ProductsGET>(DEFAULT_PATH);
};

export const getProductByID = async (productID: any) => {
  return await carttieAPI.get<ProductsDTO>(PRODUCT_BY_ID, {
    params: { productID },
  });
};

export const createCustomProducts = async (data: any) => {
  return await carttieAPI.post<ProductsGET>(DEFAULT_PATH, { ...data });
};
