const initial_products: any = { products: [] };

const productsReducer = (state = initial_products, action: any) => {
  const { data, type } = action || {};
  switch (type) {
    case "SET_PRODUCTS":
      return {
        ...state,
        products: data.products,
      };
    case "ADD_PRODUCTS":
      return {
        ...state,
        products: { ...state.products, [data.id]: data },
      };
    default:
      return state;
  }
};

export { productsReducer, initial_products };
