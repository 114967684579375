const initial_user: any = { uid: null };

const userReducer = (state = initial_user, action: any) => {
  const { data, type } = action || {};
  switch (type) {
    case "SET_LOGIN":
      return {
        ...state,
        uid: data.uid,
      };
    default:
      return state;
  }
};

export { userReducer, initial_user };
