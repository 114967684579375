import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Providers } from "./app/providers";

let translationPL = require("./shared/locale/i18n/pl/translation.json");
let translationEN = require("./shared/locale/i18n/en/translation.json");

i18n.use(initReactI18next).init({
  resources: {
    en: translationEN,
    pl: translationPL,
  },
  lng: "pl",
  fallbackLng: "en",
  supportedLngs: ["pl", "en"],
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Providers>
    <App />
  </Providers>
);
