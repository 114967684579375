import { MdCloseFullscreen, MdOpenInFull } from "react-icons/md";
import { GRAY_ICON_COLOR } from "../../../../variables/colors.variables";

type FullscreenButtonProps = {
  isFullscreenActive: boolean;
  setIsFocused: (isFocused: boolean) => void;
  setIsFullScreenActive: (isFullScreenActive: boolean) => void;
};

const FullscreenButton = ({
  isFullscreenActive,
  setIsFocused,
  setIsFullScreenActive,
}: FullscreenButtonProps) => {
  return (
    <button
      className="ShoppingList__closeButton"
      onClick={(e) => {
        e.stopPropagation();
        setIsFocused(!isFullscreenActive);
        setIsFullScreenActive(!isFullscreenActive);
      }}
    >
      {!isFullscreenActive ? (
        <MdOpenInFull size={20} color={GRAY_ICON_COLOR} />
      ) : (
        <MdCloseFullscreen size={20} color={GRAY_ICON_COLOR} />
      )}
    </button>
  );
};

export default FullscreenButton;
