import { useTranslation } from "react-i18next";
import SettingOption from "../../components/SettingOption";
import { Box } from "@chakra-ui/react";
import { SETTINGS_OPTIONS } from "../../const";

const Settings = () => {
  const { t } = useTranslation();
  return (
    <Box w="100%" h="80vh" mt="90px" className="Settings">
      {SETTINGS_OPTIONS.map(({ title, iconPath, onClick, options }) => (
        <SettingOption
          key={title}
          title={t(title)}
          options={options ?? null}
          iconPath={iconPath}
          onClick={onClick}
        />
      ))}
    </Box>
  );
};

export default Settings;
