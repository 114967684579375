import { useTranslation } from "react-i18next";
import { Button, Flex } from "@chakra-ui/react";

type ModalActionButtonsProps = {
  onCancel: () => void;
  onAction: () => void;
  actionLabel?: string;
  cancelLabel?: string;
};

const ModalActionButtons = ({
  onCancel,
  onAction,
  actionLabel,
  cancelLabel,
}: ModalActionButtonsProps) => {
  const { t } = useTranslation();
  return (
    <Flex gap={5}>
      <Button w="150px" bg="red.300" onClick={() => onCancel()}>
        {cancelLabel ?? t("Cancel")}
      </Button>
      <Button w="150px" bg="green.300" onClick={onAction}>
        {actionLabel ?? t("Create")}
      </Button>
    </Flex>
  );
};

export default ModalActionButtons;
