import { atom } from "recoil";
import { ProductsGET } from "./shared/api/products.api.type";
import { ProductInListDTO } from "./shared/api/productInList/product-in-list.api.type";
import { ShoppingListDTO } from "./shared/api/shoppingLists/shopping-lists.api.type";

/**
 * Holds a default products lookup
 */
export const ProductsAtom = atom({
  key: "ProductsAtom",
  default: [] as ProductsGET,
});

/**
 * Current opened list products
 */
export const CurrentOpenedListProductsAtom = atom({
  key: "CurrentOpenedListProductsAtom",
  default: [] as ProductInListDTO[],
});

/**
 * Holds product changes made in product search
 * What products has been deleted or added
 */
export const ProductSearchChangesAtom = atom({
  key: "ProductSearchChangesAtom",
  default: { deleted: new Set<string>(), added: new Set<string>() },
});

/**
 * Holds fetched owned shopping list from the backend
 * Could be updated on new list created
 */
export const AvailableShoppingListsAtom = atom({
  key: "AvailableShoppingListsAtom",
  default: [] as ShoppingListDTO[],
});

/**
 * Holds fetched shared shopping list from the backend
 * Could be updated on new list created
 */
export const AvailableSharedShoppingListsAtom = atom({
  key: "AvailableSharedShoppingListsAtom",
  default: [] as ShoppingListDTO[],
});

type UserInfo = {
  full_name: string;
  uid: string;
};

export const UserInfoAtom = atom({
  key: "UserInfoAtom",
  default: {} as UserInfo,
});
