import {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useClickAway } from "@uidotdev/usehooks";
import dayjs from "dayjs";
import { MdCheck, MdDeleteOutline } from "react-icons/md";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { useRecoilState, useRecoilValue } from "recoil";

import NewListForm from "../new-list-form";
import HeaderOptions from "./components/header-options";
import "./index.scss";
import { AppContext } from "../../../app/contexts/appState.context";
import ProductSearch from "../product-search";
import FullscreenButton from "./components/fullscreen-button";
import NoProducts from "./components/no-products";
import { prepProductsToDisplay } from "./utils/prepProductsToDisplay";
import { ShoppingListElementGroup } from "./components/shopping-list-element-group";
import { ShoppingListDTO } from "../../api/shoppingLists/shopping-lists.api.type";
import {
  CurrentOpenedListProductsAtom,
  ProductSearchChangesAtom,
  ProductsAtom,
  UserInfoAtom,
} from "../../../atoms";
import { ProductTypesLookup } from "../../variables/product-types.variables";
import { useTranslation } from "react-i18next";
import { bulkUpdateProductsInTheList } from "../../api/productInList/product-in-list.api";
import { CheckIcon } from "@chakra-ui/icons";
import { ProductInListDTO } from "../../api/productInList/product-in-list.api.type";
import { deleteShoppingList } from "../../api/shoppingLists/shopping-lists.api";
import { getProductByID } from "../../api/products.api";

type ShoppingListProps = {
  data: ShoppingListDTO;
  sendMessage: (message: any) => void;
};

const ShoppingList = forwardRef(
  ({ data, sendMessage }: ShoppingListProps, refForwarded): JSX.Element => {
    // Helpers
    const { t } = useTranslation();
    const { appState, dispatch: dispatchAppState } = useContext(AppContext);
    // States
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [touchStart, setTouchStart] = useState<null | number>(null);
    const [touchEnd, setTouchEnd] = useState<null | number>(null);
    const [isFullscreenActive, setIsFullScreenActive] =
      useState<boolean>(false);
    const [isAddProductModeActive, setIsAddProductModeActive] =
      useState<boolean>(false);
    const [newProductsInList, setNewProductsInList] = useState<{
      [key: string]: ProductInListDTO;
    }>({});
    const [isEditListModeOn, setIsEditListModeOn] = useState<boolean>(false);
    const [internalProductInList, setInternalProductsInList] = useState(
      data.products
    );

    // Atoms
    const user = useRecoilValue(UserInfoAtom);
    const [products, setProducts] = useRecoilState(ProductsAtom);
    const [productsInShoppingList, setProductsInShoppingList] = useRecoilState(
      CurrentOpenedListProductsAtom
    );

    useEffect(() => {
      if (data.products && (isFocused || isFullscreenActive)) {
        const getMissingProducts = async () => {
          const productsPromises: Promise<any>[] = [];
          data.products.map((productInList) => {
            if (
              !products.find(
                (product) => product.id === productInList.productReference
              )
            ) {
              productsPromises.push(
                getProductByID(productInList.productReference)
              );
            }
          });

          return Promise.all(productsPromises);
        };

        getMissingProducts().then((results: any) => {
          if (results.length) {
            setProducts((prev) => [
              ...prev,
              ...results.map((r: any) => r?.data),
            ]);
          }
          setProductsInShoppingList(data.products);
          setInternalProductsInList(data.products);
        });
      }
    }, [
      isFocused,
      data.products,
      isFullscreenActive,
      setProductsInShoppingList,
    ]);

    const { countOfProductsLeft, checkedOutProducts, productsLeft } = useMemo(
      () => prepProductsToDisplay(internalProductInList, products),
      [internalProductInList, products]
    );

    useEffect(() => {
      if (isFullscreenActive) {
        dispatchAppState({
          type: "SET_FLOATING_BUTTON",
          data: {
            ...appState.floatingButtonState,
            icon: isAddProductModeActive ? <CheckIcon boxSize={4} /> : null,
            callback: () => {
              if (isAddProductModeActive) {
                updateProductsInList();
                closeProductAddingMode();
              } else {
                setIsAddProductModeActive(true);
              }
            },
          },
        });
      }
    }, [isFullscreenActive, isAddProductModeActive]);

    //TODO: Fullscreen doesn;t need this, but preview mode does. Fix this
    const ref: any = useClickAway(() => {
      //    setIsFocused(false);
      //    setIsFullScreenActive(false);
      //    dispatchAppState({
      //      type: "SET_LIST_IS_UN_OPEN",
      //    });
    });

    const innerHolderTouchRef = useRef<HTMLDivElement>(null);

    const onTouchStart = (e: any) => {
      setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
      setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (touchStart && innerHolderTouchRef.current) {
        const currentPosition = e.targetTouches[0].clientX;
        const numericValue = Math.abs(
          parseFloat(
            window
              .getComputedStyle(innerHolderTouchRef.current)
              .getPropertyValue("transform")
              .split("(")[1]
              .split(")")[0]
              .split(",")[4]
          )
        );
        const distanceScroll = currentPosition - touchStart || numericValue;

        if (
          distanceScroll < 0 &&
          numericValue < 50 &&
          innerHolderTouchRef.current
        ) {
          e.stopPropagation();
          e.preventDefault();
          setTouchEnd(currentPosition);
          innerHolderTouchRef.current.style.transform = `translateX(${distanceScroll}px)`;
        } else if (distanceScroll > 0) {
          e.stopPropagation();
          e.preventDefault();
          innerHolderTouchRef.current.style.transform = `translateX(0px)`;
        }
      }
    };

    const onTouchEnd = () => {
      if (!touchStart || !touchEnd) return;
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > 0;

      if (innerHolderTouchRef.current) {
        if (isLeftSwipe) {
          if (distance > 40) {
            innerHolderTouchRef.current.style.transform = `translateX(-60px)`;
          } else {
            innerHolderTouchRef.current.style.transform = `translateX(0px)`;
          }
        } else {
          innerHolderTouchRef.current.style.transform = `translateX(0px)`;
        }
      }
    };
    const [productSearchChanges, setProductSearchChanges] = useRecoilState(
      ProductSearchChangesAtom
    );

    const updateProductsInList = async () => {
      const payload = {
        listReference: data.id,
        added: Array.from(productSearchChanges.added),
        deleted: Array.from(productSearchChanges.deleted),
      };
      const newProducts = await bulkUpdateProductsInTheList(payload);
      setProductsInShoppingList(newProducts.data);
      setInternalProductsInList(newProducts.data);
      await setProductSearchChanges({
        deleted: new Set<string>(),
        added: new Set<string>(),
      });
    };

    const closeProductAddingMode = () => {
      setNewProductsInList({});
      setIsAddProductModeActive(false);
    };

    const sendUpdateInformation = () => {
      sendMessage(
        JSON.stringify({
          id: data.id,
          uid: user.uid,
          sharedWith: [...data.shared_with, data.created_by],
        })
      );
    };

    return (
      <div
        className={`ShoppingList ${isFocused ? "focused" : ""} ${
          isFullscreenActive ? "fullscreen" : ""
          //@ts-expect-error it is
        }${window?.Capacitor ? " mobileApp" : ""}`}
        ref={ref}
        onClick={(e) => {
          setIsFullScreenActive(true);
          dispatchAppState({
            type: "SET_LIST_IS_OPEN",
          });
          setIsFocused(true);
        }}
      >
        <div
          className="ShoppingList__header"
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
          onClick={(e) => {
            if (isFocused) {
              e.stopPropagation();
              setIsFullScreenActive(false);
              dispatchAppState({
                type: "SET_LIST_IS_UN_OPEN",
              });
              setIsFocused(false);
              setIsAddProductModeActive(false);
            }
          }}
        >
          <div
            ref={innerHolderTouchRef}
            className="ShoppingList__header__innerHolder"
          >
            <div className="ShoppingList__header__infoHolder">
              <h4>{data.list_name}</h4>
              <span className="ShoppingList__header__creationDate">
                {dayjs(data.created).format("DD.MM.YYYY")}
              </span>
              <HeaderOptions
                setIsEditListModeOn={(state: boolean) => {
                  setIsEditListModeOn(state);
                  dispatchAppState({
                    type: state ? "SET_LIST_IS_OPEN" : "SET_LIST_IS_UN_OPEN",
                  });
                }}
                shoppingListDocumentReference={data.id}
              />
            </div>
            <button
              className="ShoppingList__header__deleteButton"
              onClick={async (e) => {
                e.stopPropagation();
                try {
                  await deleteShoppingList({ listReference: data.id });
                  // Hide shopping list after delete
                  ref.current.style.display = "none";
                } catch {}
              }}
            >
              <MdDeleteOutline size={20} color="white" />
            </button>
          </div>
        </div>
        {!isFocused && (
          <span className="ShoppingList__elementsLeft">
            {productsLeft && data.products.length ? (
              countOfProductsLeft || (
                <span className="ShoppingList__elementsLeft__green">
                  <MdCheck size={15} />
                </span>
              )
            ) : (
              <span className="ShoppingList__elementsLeft__red">-</span>
            )}
          </span>
        )}
        <div className="ShoppingList__elementsHolders">
          {!isAddProductModeActive && isFocused && (
            <>
              <div className="ShoppingList__elementsHolder">
                {Object.keys(productsLeft).length > 0 && (
                  <div className="ShoppingList__elementsHolder__groupHolder">
                    {Object.keys(productsLeft)?.map((productType) => {
                      return (
                        <ShoppingListElementGroup
                          key={productType}
                          sendMessage={sendUpdateInformation}
                          categoryName={ProductTypesLookup[productType]}
                          products={productsLeft[productType]}
                          productsInShoppingList={productsInShoppingList as any}
                          shoppingListRef={data.id}
                          setInternalProductsInList={setInternalProductsInList}
                        />
                      );
                    })}
                  </div>
                )}
                {Object.keys(checkedOutProducts).length > 0 && (
                  <div className="ShoppingList__elementsHolder__checkedOut">
                    {Object.keys(checkedOutProducts).map((productType) => {
                      return (
                        <ShoppingListElementGroup
                          key={productType}
                          sendMessage={sendUpdateInformation}
                          categoryName={ProductTypesLookup[productType]}
                          products={checkedOutProducts[productType]}
                          productsInShoppingList={productsInShoppingList as any}
                          shoppingListRef={data.id}
                          setInternalProductsInList={setInternalProductsInList}
                        />
                      );
                    })}
                  </div>
                )}
                {Object.keys(checkedOutProducts).length <= 0 &&
                  Object.keys(productsLeft).length <= 0 && (
                    <NoProducts
                      areTheyCheckoutProducts={
                        Object.keys(checkedOutProducts).length > 0
                      }
                    />
                  )}
              </div>
              <FullscreenButton
                isFullscreenActive={isFullscreenActive}
                setIsFullScreenActive={setIsFullScreenActive}
                setIsFocused={setIsFocused}
              />
            </>
          )}
        </div>
        {isAddProductModeActive &&
          isFullscreenActive &&
          productsInShoppingList && (
            <ProductSearch
              newProductsInList={newProductsInList}
              setNewProductsInList={setNewProductsInList}
            />
          )}
        {isEditListModeOn && (
          <NewListForm
            isOpen={true}
            setIsNewListFormOpen={() => setIsEditListModeOn(false)}
            currentListData={data}
          />
        )}
        <button
          className="ShoppingList__previewButton"
          onClick={(e) => {
            e.stopPropagation();
            setIsFocused(!isFocused);
            //@ts-expect-error dsds
            if (refForwarded?.current) {
              const srcollToCalc = ref.current.offsetTop * 0.8;

              // We need to wait for scroll box to arise
              setTimeout(() => {
                //@ts-expect-error das
                refForwarded.current.scroll(0, srcollToCalc);
              }, 190);
            }
          }}
        >
          {isFocused ? <FaArrowUp size={10} /> : <FaArrowDown size={10} />}
        </button>
      </div>
    );
  }
);

export default ShoppingList;
