import axios from "axios";
import { refreshAccessToken } from "./otp/otp.api";

let baseURL;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  baseURL = "";
} else {
  baseURL = "carttie.pl";
}

export const carttieAPI = axios.create({
  baseURL: "/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

carttieAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = token;
    } else {
      delete carttieAPI.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

carttieAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if ([401].includes(error.response.status) && !error._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("refreshToken");

        if (!refreshToken) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          throw new Error("Bad refresh token");
        }

        const response = await refreshAccessToken(refreshToken);

        const { accessToken } = response.data;

        localStorage.setItem("accessToken", accessToken);

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `${accessToken}`;
        return axios(originalRequest);
      } catch (error) {
        console.table(error);
        // Handle refresh token error or redirect to login
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        const path = new URL(window.location.href);
        if (path.pathname !== "/auth") {
          window.location.href = "/auth";
        }
      }
    }

    return Promise.reject(error);
  }
);
