import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "./query-client.provider";
import { AppContextProvider } from "./app-context.provider";
import { ChakraProvider } from "./chakra.provider";
import { RecoilProvider } from "./recoil.provider";

export const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <BrowserRouter>
      <RecoilProvider>
        <AppContextProvider>
          <ChakraProvider>
            <QueryClientProvider>{children}</QueryClientProvider>
          </ChakraProvider>
        </AppContextProvider>
      </RecoilProvider>
    </BrowserRouter>
  );
};
