export function handleError(error: any) {
  // TODO: extend
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.error(error);
  }
}

export const isEmailValid = (email: string) => {
  return false;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !emailRegex.test(email);
};
