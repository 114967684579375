import { useState } from "react";
import { useLongPress } from "@uidotdev/usehooks";
import {
  Box,
  Button,
  Flex,
  Input,
  Portal,
  Slide,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  CurrentOpenedListProductsAtom,
  ProductsAtom,
} from "../../../../../atoms";
import { ProductInListDTO } from "../../../../api/productInList/product-in-list.api.type";
import { updateProductInList } from "../../../../api/productInList/product-in-list.api";
import beepSound from "../../../../../assets/beepSound.mp3";
import useSound from "use-sound";
import ModalActionButtons from "../../../modal-action-buttons";
import { useTranslation } from "react-i18next";
import { UnitHolder } from "./components/unit-holder";
import { motion, useIsPresent } from "framer-motion";

type ShoppingListElementProps = {
  productData: ProductInListDTO;
  shoppingListRef?: string;
  sendMessage: any;
  setInternalProductsInList: (newProductsInList: ProductInListDTO[]) => any;
};

/** Component used for displaying product inside shopping list (normal mode) */
const ShoppingListElement = ({
  productData,
  sendMessage,
  shoppingListRef,
  setInternalProductsInList,
}: ShoppingListElementProps) => {
  // Helpers
  const [playSound] = useSound(beepSound);
  const { t } = useTranslation();
  // States
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [internalProductState, setInternalProductState] =
    useState<ProductInListDTO | null>(productData);
  // Atoms
  const [productsInShoppingList, setProductsInShoppingList] = useRecoilState(
    CurrentOpenedListProductsAtom
  );
  const products = useRecoilValue(ProductsAtom);

  const productToUse = products.find(
    (product) => product.id === productData?.productReference
  );

  const updateAtomsProducts = (
    key: keyof ProductInListDTO,
    value: number | string | boolean
  ) => {
    playSound();
    const newProductsInList = [...productsInShoppingList].map((item) => {
      if (productData?.id === item.id) return { ...item, [key]: value };
      else return item;
    });
    setProductsInShoppingList(newProductsInList);
    setInternalProductsInList(newProductsInList);
  };

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const patchProduct = async () => {
    await updateProductInList({
      productReference: productData?.id,
      listReference: shoppingListRef,
      alterName: internalProductState?.alterName ?? "",
      quantity: internalProductState?.quantity ?? 1,
      unit: internalProductState?.unit ?? "",
    });
    await setTimeout(() => {
      onToggle();
    }, 1500);
  };

  const attrs = useLongPress(
    () => {
      onOpen();
      setIsModalShown(true);
    },
    {
      onStart: (event) => "Press started",
      onFinish: (event) => "Press Finished",
      onCancel: (event) => "Press cancelled",
      threshold: 50,
    }
  );
  const variants = {
    rotate: {
      textDecoration: "line-through",
      transition: { duration: 6 },
    },
    // You can do whatever you want here, if you just want it to stop completely use `rotate: 0`
  };

  const isPresent = useIsPresent();
  const animations = {
    style: {
      position: isPresent ? "static" : "absolute",
    },
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
    transition: { type: "spring", stiffness: 900, damping: 40 },
  };

  if (!productToUse || !internalProductState) {
    return <></>;
  }

  return (
    // @ts-expect-error 123
    <Flex
      w="100%"
      height="40px"
      alignItems="center"
      gap="16px"
      padding="0 10px"
      textTransform="capitalize"
      justifyContent="space-between"
      as={motion.div}
      {...animations}
      layout
      position="relative"
      //     {...attrs}
    >
      <Flex gap="10px">
        <Text minW="30px">{productData.quantity}x</Text>
        <UnitHolder unit={productData.unit ?? t("pcs")} />
        <Text
          as={motion.span}
          variants={variants}
          animate={productData?.isChecked ? "rotate" : "stop"}
          initial="hidden"
          //textDecoration={productData?.isChecked ? "line-through" : ""}
          onClick={onToggle}
        >
          {productData.alterName ?? productToUse.name ?? "unknown"}{" "}
        </Text>
      </Flex>
      <Button
        w="32px"
        h="32px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow="visible"
        bg="none"
        marginRight="15px"
        border="solid 1px"
        minW="32px"
        p="0"
        onClick={async (e) => {
          if (productData) {
            e.stopPropagation();
            const newIsChecked = !productData?.isChecked;
            //@ts-expect-error 123
            setInternalProductState({ ...internalProductState, started: true });
            updateAtomsProducts("isChecked", newIsChecked);
            await updateProductInList({
              isChecked: newIsChecked,
              productReference: productData?.id,
              listReference: shoppingListRef,
            });
            sendMessage();
          }
        }}
      >
        {internalProductState?.isChecked && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            width={50} // for safari
            stroke="currentColor"
          >
            <motion.path
              strokeLinecap="round"
              strokeLinejoin="round"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1 }}
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        )}
      </Button>
      {isOpen && (
        <Portal>
          <Slide
            direction="bottom"
            in={isOpen}
            style={{ zIndex: 1001 }}
            unmountOnExit={true}
          >
            <Box
              display="flex"
              justifyContent="center"
              p="35px"
              color="brandDark.500"
              mt="4"
              rounded="md"
              bg="white"
              boxShadow="3px 2px 10px -5px #6e6e6e"
            >
              <Flex flexWrap="wrap" maxW="320px">
                <Text mb="8px">{t("Name")}</Text>
                <Input
                  mb="20px"
                  value={
                    internalProductState.alterName ?? productToUse.name ?? ""
                  }
                  onChange={(e) => {
                    //@ts-expect-error 123
                    setInternalProductState((prev) => ({
                      ...prev,
                      alterName: e?.target?.value,
                    }));
                  }}
                />
                <Flex mb="20px" gap="20px" justifyContent="space-between">
                  <Flex w="50%" flexWrap="wrap">
                    <Text w="100%" mb="8px">
                      {t("Quantity")}
                    </Text>
                    <Input
                      defaultValue={internalProductState.quantity}
                      type="number"
                      onChange={(e) => {
                        //@ts-expect-error 123
                        setInternalProductState((prev) => ({
                          ...prev,
                          quantity: e.target.value ?? 0,
                        }));
                      }}
                    />
                  </Flex>
                  <Flex w="50%" flexWrap="wrap">
                    <Text w="100%" mb="8px">
                      {t("Unit")}
                    </Text>
                    <Input
                      value={internalProductState.unit ?? ""}
                      onChange={(e) => {
                        //@ts-expect-error 123
                        setInternalProductState((prev) => ({
                          ...prev,
                          unit: e.target.value ?? "",
                        }));
                      }}
                    />
                  </Flex>
                </Flex>
                <ModalActionButtons
                  actionLabel={t("Save")}
                  onCancel={onToggle}
                  onAction={patchProduct}
                />
              </Flex>
            </Box>
          </Slide>
        </Portal>
      )}
    </Flex>
  );
};

export default ShoppingListElement;
