import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { isEmailValid } from "../../../shared/utils/utils";
import { APP_COLOR } from "../../../shared/variables/colors.variables";
import { useTranslation } from "react-i18next";
import { createUserAccount } from "../../../shared/api/user-account/user.api";
import { useNavigate } from "react-router-dom";

export const TermsAndConditions = () => {
  // Helpers
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  // State
  const [isFormError, setIsFormError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");

  const onSubmit = async () => {
    if (isFormError) {
      return null;
    }

    try {
      await createUserAccount({ username, email });

      // If 200, navigate to login
      toast({
        title: "Account",
        description: `Account created, you can log in`,
        status: "success",
        duration: 90000,
        isClosable: true,
      });
      navigate("/auth");
    } catch (error) {}
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      flexWrap="wrap"
      gap="5px"
    >
      <Text fontSize="26px" w="100%" pb="5px">
        Sign in
      </Text>
      <FormControl isInvalid={isFormError}>
        <FormLabel>Name</FormLabel>
        <Input
          value={username}
          onInput={(e) => setUsername(e.currentTarget.value)}
          placeholder="e.g. John.doe"
        />
        <FormLabel mt="10px">Email address</FormLabel>
        <Input
          value={email}
          onInput={(e) => {
            setEmail(e.currentTarget.value);
          }}
          onBlur={() => setIsFormError(isEmailValid(email))}
          type="email"
          placeholder="email@example.com"
        />
        {!isFormError ? (
          <FormHelperText>We'll never share your email.</FormHelperText>
        ) : (
          <FormErrorMessage>Email is invalid.</FormErrorMessage>
        )}
      </FormControl>
      <Button
        // isLoading={isLoading}
        isDisabled={isFormError}
        w="80%"
        mt="10px"
        bg={APP_COLOR}
        color="white"
        onClick={onSubmit}
      >
        {t("Sign in")}
      </Button>
    </Flex>
  );
};
