import { createContext } from "react";
import { memo, useReducer } from "react";
import { initial_products, productsReducer } from "./reducers/products.reducer";

export const ProductsContext = createContext(initial_products);

export const ProductState = memo(({ children }: any) => {
  const [products, dispatch] = useReducer(productsReducer, initial_products);

  return (
    <ProductsContext.Provider value={{ products, dispatch }}>
      {children}
    </ProductsContext.Provider>
  );
});
