import { useTranslation } from "react-i18next";
import { ProductInListDTO } from "../../../../api/productInList/product-in-list.api.type";
import ShoppingListElement from "../shopping-list-element";
import { Box, Text } from "@chakra-ui/react";
import { ICONS } from "../../../../variables/product-types.variables";
import { ProductsAtom } from "../../../../../atoms";
import { useRecoilValue } from "recoil";
import { AnimatePresence } from "framer-motion";

type ShoppingListElementGroupProps = {
  categoryName: string;
  products: ProductInListDTO[];
  sendMessage: any;
  productsInShoppingList: {
    [key: string]: ProductInListDTO;
  };
  shoppingListRef?: string;
  setInternalProductsInList: (newProductsInList: ProductInListDTO[]) => any;
};

export const ShoppingListElementGroup = ({
  categoryName,
  products,
  sendMessage,
  shoppingListRef,
  setInternalProductsInList,
}: ShoppingListElementGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const defaultProducts = useRecoilValue(ProductsAtom);
  const productToUse = defaultProducts.find(
    (product) => product.id === products[0]?.productReference
  );

  return (
    <Box minW="100%">
      <Text
        position="sticky"
        top="0"
        zIndex="1"
        bg="brandDark.500"
        color="white"
        padding="10px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
        textTransform="capitalize"
        minW="100%"
      >
        <Box
          pr="10px"
          as="i"
          className={`fi ${ICONS?.[productToUse?.type ?? ""]}`}
        ></Box>
        {t(categoryName)}
      </Text>
      <AnimatePresence>
        {products?.map((product) => (
          <ShoppingListElement
            key={product.id}
            sendMessage={sendMessage}
            productData={product}
            shoppingListRef={shoppingListRef}
            setInternalProductsInList={setInternalProductsInList}
          />
        ))}
      </AnimatePresence>
    </Box>
  );
};
