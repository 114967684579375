import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Input, InputGroup } from "@chakra-ui/react";
import { SaveCancelModal } from "../save-cancel-modal";
import {
  createShoppingLists,
  updateShoppingListName,
} from "../../api/shoppingLists/shopping-lists.api";
import { AvailableShoppingListsAtom } from "../../../atoms";
import { useRecoilState } from "recoil";
import { ShoppingListDTO } from "../../api/shoppingLists/shopping-lists.api.type";

type NewListFormProps = {
  isOpen: boolean;
  setIsNewListFormOpen: Dispatch<SetStateAction<boolean>>;
  currentListData?: ShoppingListDTO;
};

const NewListForm = ({
  isOpen,
  setIsNewListFormOpen,
  currentListData,
}: NewListFormProps) => {
  // Helpers
  const { t } = useTranslation();

  //States
  const [newShoppingListName, setNewShoppingListName] = useState<string>(
    currentListData?.list_name ?? ""
  );
  //Atoms
  const [shoppingListsData, setShoppingListsData] = useRecoilState(
    AvailableShoppingListsAtom
  );

  const onClose = () => {
    setIsNewListFormOpen(false);
    setNewShoppingListName("");
  };

  const onAction = async () => {
    try {
      if (currentListData) {
        updateShoppingListName({
          listReference: currentListData.id,
          list_name: newShoppingListName,
        });
        setShoppingListsData((prevState) =>
          prevState.map((list) => {
            if (list.id === currentListData.id) {
              const modifiedList = { ...list };
              modifiedList.list_name = newShoppingListName;
              return modifiedList;
            }
            return list;
          })
        );
      } else {
        const createdList = await createShoppingLists({
          list_name: newShoppingListName,
        });
        setShoppingListsData((prevState) => [...prevState, createdList.data]);
      }
    } catch (error) {
      console.log(error);
    }
    setIsNewListFormOpen(false);
    setNewShoppingListName("");
  };

  return (
    <SaveCancelModal
      isOpen={isOpen}
      onClose={onClose}
      onSave={onAction}
      title={t("Create new list")}
      actionLabel={currentListData ? t("Update") : t("Save")}
      modalBody={
        <Flex w="100%" maxW="800px" justifyContent="center" flexWrap="wrap">
          <InputGroup maxW="480px">
            <Input
              autoFocus
              onInput={(e) => setNewShoppingListName(e.currentTarget.value)}
              value={newShoppingListName}
              type="text"
              placeholder={t("Your list name here")}
            />
          </InputGroup>
        </Flex>
      }
    />
  );
};

export default NewListForm;
