import {
  QueryClient,
  QueryClientProvider as QueryClientContainer,
} from "react-query";

export const QueryClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientContainer client={queryClient}>{children}</QueryClientContainer>
  );
};
