import { Route, Routes } from "react-router-dom";
import SharedListActivate from ".";

export const SharedListActivateRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="invite-accept" element={<SharedListActivate />} />
    </Routes>
  );
};
