import { carttieAPI } from "../axios";

const USER_PATH = "user/";
const ME_PATH = `${USER_PATH}me`;

type CreateUserAccountPayload = {
  /**
   * Name of the user
   */
  username: string;
  /**
   * email of the user
   */
  email: string;
};

export const createUserAccount = async (data: CreateUserAccountPayload) =>
  await carttieAPI.post(USER_PATH, { ...data });

export const getCurrentUserInformation = async () =>
  await carttieAPI.get(ME_PATH);
