const INITAL_FLOATING_BUTTON = {
  callback: () => {},
  icon: null,
  text: null,
};

const initial_appState: any = {
  isAnyListOpened: false,
  floatingButtonState: INITAL_FLOATING_BUTTON,
};

const appStateReducer = (state = initial_appState, action: any) => {
  const { data, type } = action || {};
  switch (type) {
    case "SET_LIST_IS_OPEN":
      return {
        ...state,
        isAnyListOpened: true,
      };
    case "SET_LIST_IS_UN_OPEN":
      return {
        ...state,
        isAnyListOpened: false,
      };
    case "SET_FLOATING_BUTTON":
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};

export { appStateReducer, initial_appState };
