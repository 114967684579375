import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SaveCancelModal } from "../save-cancel-modal";
import { Flex, Input, InputGroup, Select } from "@chakra-ui/react";
import { ProductTypesLookup } from "../../variables/product-types.variables";

type ProductEditProps = {
  closeModalFunc: () => void;
  onProductSave: (product: any) => void;
  isOpen: boolean;
  startNameValue?: string;
};

const ProductEdit = ({
  closeModalFunc,
  onProductSave,
  isOpen,
  startNameValue = "",
}: ProductEditProps) => {
  const { t } = useTranslation();
  const [productNameForm, setProductNameForm] =
    useState<string>(startNameValue);
  const [productCategory, setProductCategory] = useState<string>("0");

  return (
    <SaveCancelModal
      isOpen={isOpen}
      onClose={closeModalFunc}
      onSave={() => {
        onProductSave({
          name: productNameForm,
          category: productCategory,
        });
        closeModalFunc();
      }}
      title={t("Create new product")}
      actionLabel={t("Save")}
      modalBody={
        <Flex w="100%" maxW="800px" justifyContent="center" flexWrap="wrap">
          <InputGroup maxW="480px" flexWrap="wrap" gap={4}>
            <Input
              type="text"
              placeholder={t("Product name")}
              onInput={(e) => setProductNameForm(e.currentTarget.value)}
              value={productNameForm}
            />
            <Select onChange={(e) => setProductCategory(e.target.value)}>
              {Object.entries(ProductTypesLookup).map(
                ([typeID, typeName]: any) => (
                  <option key={typeID} value={typeID}>
                    {t(typeName)}
                  </option>
                )
              )}
            </Select>
          </InputGroup>
        </Flex>
      }
    />
  );
};

export default ProductEdit;
