import { carttieAPI } from "../axios";
import { ShoppingListInvitationDTO } from "./invitation.api.type";

const BASE_INVITATION_URL = "/invitation/";
const START_INVITATION = `${BASE_INVITATION_URL}start`;
const END_INVITATION = `${BASE_INVITATION_URL}end`;

type CreateShoppingListInvitationDataType = {
  /**
   * ID of the shopping list
   * @example 662f7894ad389176fd53768a
   */
  listReference: string;
};

export const createShoppingListInvitation = async (
  data: Partial<CreateShoppingListInvitationDataType>
) =>
  await carttieAPI.post<ShoppingListInvitationDTO>(START_INVITATION, {
    ...data,
  });

type EndShoppingListInvitationDataType = {
  /**
   * ID of the invitation
   * @example 662f7894ad389176fd53768a
   */
  invitationID: string;
};

export const endShoppingListInvitation = async (
  data: Partial<EndShoppingListInvitationDataType>
) =>
  await carttieAPI.post<ShoppingListInvitationDTO>(END_INVITATION, {
    ...data,
  });
