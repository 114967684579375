import { Route, Routes } from "react-router-dom";
import Settings from "./screens/settings";
import { SettingsLayout } from "./layouts/settings.layout";

export const SettingsRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<SettingsLayout />}>
        <Route path="/settings" element={<Settings />} />
      </Route>
    </Routes>
  );
};
