import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "../../app/router/private-route";
import { useContext } from "react";
import { UserContext } from "../../app/contexts/user.context";
import Home from ".";
import { HomeLayout } from "./layouts/home.layout";

export const HomeRoutes = (): JSX.Element => {
  const { user } = useContext(UserContext);

  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route
          path=""
          element={<PrivateRoute user={user.uid} element={<Home />} />}
        />
      </Route>
    </Routes>
  );
};
