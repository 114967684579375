import { UserState } from "../contexts/user.context";
import { ProductState } from "../contexts/product.context";
import { AppState } from "../contexts/appState.context";

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <AppState>
      <UserState>
        <ProductState>{children}</ProductState>
      </UserState>
    </AppState>
  );
};
