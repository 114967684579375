import { useEffect, useState, useRef } from "react";

const URL = "://carttie.pl/api/?token=";

export const useWebSocket = () => {
  const [messages, setMessages] = useState<any>([]);
  const [isConnected, setIsConnected] = useState(false);
  const webSocketRef = useRef<null | WebSocket>(null);
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    if (!token) return;

    const PROTOCOL = process.env.NODE_ENV === "development" ? "ws" : "wss";

    webSocketRef.current = new WebSocket(`${PROTOCOL}${URL}${token}`, [
      "Authorization",
      token,
    ]);

    webSocketRef.current.onerror = (err) => {
      console.log(err);
      console.dir(err);
    };

    webSocketRef.current.onopen = () => {
      setIsConnected(true);
      console.log("WebSocket connection opened");
    };

    webSocketRef.current.onmessage = (event) => {
      setMessages((prevMessages: any) => [...prevMessages, event.data]);
    };

    webSocketRef.current.onclose = () => {
      setIsConnected(false);
      console.log("WebSocket connection closed");
    };

    return () => {
      if (webSocketRef.current) {
        webSocketRef.current.close();
      }
    };
  }, [URL]);

  const sendMessage = (message: any) => {
    if (
      webSocketRef.current &&
      isConnected &&
      webSocketRef.current.readyState === WebSocket.OPEN
    ) {
      webSocketRef.current.send(message);
    }
  };

  return { messages, sendMessage, isConnected };
};
