import { AxiosRequestConfig } from "axios";
import { carttieAPI } from "../axios";
import { ProductInListDTO } from "./product-in-list.api.type";

type UpdateProductInListDataPatchType = {
  /**
   * Product in list description
   * @example 'Cheese in blue box'
   */
  description: string;
  /**
   * Used for specific type of product
   * @example 'Pepsi max'
   */
  alterName: string;
  /**
   * Amount of product
   * @example 5
   */
  quantity: number;
  /**
   * unit of product quantity
   * @example kg, lbs
   */
  unit: string;
  /**
   * Is product marked as bought
   * @example true
   */
  isChecked: boolean;
  /**
   * ID of the list where product exist
   * @example 662f7894ad389176fd53768a
   */
  listReference: string;
  /**
   * ProductInList ID, WARN: Not the product default ID
   * @example 662f7894ad389176fd53768a
   */
  productReference: string;
};

export const updateProductInList = async (
  data: Partial<UpdateProductInListDataPatchType>
) => await carttieAPI.patch<ProductInListDTO>("products-in-list/", { ...data });

type DeleteProductInListDataType = {
  /**
   * ID of the list where product exist
   * @example 662f7894ad389176fd53768a
   */
  listReference: string;
  /**
   * ProductInList ID, WARN: Not the product default ID
   * @example 662f7894ad389176fd53768a
   */
  productReference: string;
};

export const deleteProductFromList = async (
  data: Partial<DeleteProductInListDataType>
) =>
  await carttieAPI.delete<ProductInListDTO>("products-in-list/", {
    ...data,
  } as AxiosRequestConfig);

type BulkUpdateProductInListDataType = {
  /**
   * ID of the list where product exist
   * @example 662f7894ad389176fd53768a
   */
  listReference: string;
  /**
   * List of default products id references to be deleted
   * @example [662f7894ad389176fd53768a]
   */
  deleted: string[];
  /**
   * List of default products id references to be added
   * @example [662f7894ad389176fd53768a]
   */
  added: string[];
};
export const bulkUpdateProductsInTheList = async (
  data: Partial<BulkUpdateProductInListDataType>
) =>
  await carttieAPI.post<ProductInListDTO[]>("products-in-list/bulk/", {
    ...data,
  } as AxiosRequestConfig);
