//1 - tools/ 2- fruits/ 3- vegetables/4-snacks/5-drink/6-alcohol
enum ProductTypes {
  TOOLS,
  FRUITS,
  VEGETABLES,
  SNACKS,
  DRINKS,
  ALCOHOLS,
}

type ProductTypesLookupType = {
  [key: string]: string;
};

export const ProductTypesLookup: ProductTypesLookupType = {
  [ProductTypes.TOOLS]: "Tools",
  [ProductTypes.FRUITS]: "Fruits",
  [ProductTypes.VEGETABLES]: "Vegetables",
  [ProductTypes.SNACKS]: "Snacks",
  [ProductTypes.DRINKS]: "Drinks",
  [ProductTypes.ALCOHOLS]: "Alcohol",
};

export const ICONS: any = {
  [ProductTypes.DRINKS]: "fi fi-rr-glass",
  [ProductTypes.FRUITS]: "fi-rr-apple-whole",
  // bakery: "fi-rr-bread",
  [ProductTypes.SNACKS]: "fi-rr-canned-food",
  // decoration: "fi-rr-candle-holder",
  [ProductTypes.ALCOHOLS]: "fi-rr-bottle",
  [ProductTypes.TOOLS]: "fi-rr-hammer-crash",
  [ProductTypes.VEGETABLES]: "fi fi-rr-carrot",
  new: "fi fi-rr-add",
};
