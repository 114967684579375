import { createContext } from "react";
import { memo, useReducer } from "react";
import {
  appStateReducer,
  initial_appState,
} from "./reducers/app-state.reducer";

export const AppContext = createContext(initial_appState);

export const AppState = memo(({ children }: any) => {
  const [appState, dispatch] = useReducer(appStateReducer, initial_appState);

  return (
    <AppContext.Provider value={{ appState, dispatch }}>
      {children}
    </AppContext.Provider>
  );
});
