import { Route, Routes } from "react-router-dom";
import { Auth } from "./screens/auth";
import { AuthLayout } from "./layout/auth.layout";
import { TermsAndConditions } from "./screens/sign-in";

export const AuthRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="auth" element={<Auth />} />
        <Route path="terms" element={<TermsAndConditions />} />
      </Route>
    </Routes>
  );
};
