import { carttieAPI } from "../axios";
import { ShoppingListDTO } from "./shopping-lists.api.type";

const LIST_PATH = "list";
const SHARED_LISTS = `${LIST_PATH}/shared`;

export const getShoppingLists = async () =>
  await carttieAPI.get<ShoppingListDTO[]>(LIST_PATH);

export const getSharedShoppingLists = async () =>
  await carttieAPI.get<ShoppingListDTO[]>(SHARED_LISTS);

type CreateShoppingListPayload = {
  /**
   * Name of the list
   */
  list_name: string;
};

export const createShoppingLists = async (data: CreateShoppingListPayload) =>
  await carttieAPI.post<ShoppingListDTO>(LIST_PATH, { ...data });

type UpdateShoppingListPayload = {
  /**
   * Name of the list
   */
  list_name: string;
  /**
   * Reference to the list
   */
  listReference: string;
};

export const updateShoppingListName = async (data: UpdateShoppingListPayload) =>
  await carttieAPI.patch<ShoppingListDTO>(LIST_PATH, { ...data });

type DeleteShoppingListPayload = {
  /**
   * Reference to the list
   */
  listReference: string;
};

export const deleteShoppingList = async (data: DeleteShoppingListPayload) =>
  await carttieAPI.delete<DeleteShoppingListPayload>(LIST_PATH, {
    data,
  });
