import { useTranslation } from "react-i18next";
import ModalActionButtons from "../modal-action-buttons";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

type SaveCancelModalProps = {
  isOpen: boolean;
  onSave: () => any;
  onClose: () => any;
  title: string;
  actionLabel: string;
  modalBody: JSX.Element;
};

export const SaveCancelModal = ({
  isOpen,
  onSave,
  onClose,
  title,
  actionLabel,
  modalBody,
}: SaveCancelModalProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{modalBody}</ModalBody>
        <ModalFooter justifyContent="center">
          <ModalActionButtons
            onAction={() => {
              onSave();
              toast({
                title: t("Saved"),
                status: "success",
                duration: 2000,
                isClosable: true,
              });
            }}
            onCancel={onClose}
            actionLabel={actionLabel}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
