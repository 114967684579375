import { useTranslation } from "react-i18next";
import noProductsImage from "../../../../../assets/no-products.svg";
import { Flex, Image, Text } from "@chakra-ui/react";

type NoProductsType = {
  areTheyCheckoutProducts: boolean;
};

const NoProducts = ({ areTheyCheckoutProducts }: NoProductsType) => {
  const { t } = useTranslation();
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      gap="30px"
      flexWrap="wrap"
      w="100%"
    >
      <Text w="230px" mt="100px" textAlign="center" fontWeight="600">
        {areTheyCheckoutProducts
          ? t("No items left")
          : t(
              "There are no products in the list. Click to to add first product"
            )}
      </Text>
      <Flex w="100%" justifyContent="center">
        <Image src={noProductsImage} alt="Shopping bag" w="130px" />
      </Flex>
      <Flex w="100%" justifyContent="center" alignItems="center"></Flex>
    </Flex>
  );
};

export default NoProducts;
