import { AuthRoutes } from "../../modules/auth/auth.routes";
import { HomeRoutes } from "../../modules/home/home.router";
import { SettingsRoutes } from "../../modules/settings/settings.routes";
import { SharedListActivateRoutes } from "../../modules/shared-list-activate/shared-list-activate.router";

export const Router = () => {
  return (
    <>
      <SettingsRoutes />
      <AuthRoutes />
      <HomeRoutes />
      <SharedListActivateRoutes />
    </>
  );
};
