import {
  Avatar,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";

import logo from "./../../../assets/logo.png";
import { UserInfoAtom } from "../../../atoms";

const TopBar = () => {
  const user = useRecoilValue(UserInfoAtom);

  return (
    <Flex
      className="TopBar"
      alignItems="center"
      w="100%"
      bg="white"
      borderRadius="0 0 5px 5px"
      boxShadow="3px 2px 10px -5px #6e6e6e"
      height="60px"
      position="absolute"
      justifyContent="space-between"
      top="0"
    >
      <Image width="50px" src={logo} ml="15px" alt="logo" />
      <Menu arrowPadding={0}>
        <MenuButton w="90px">
          <Avatar
            key="unique"
            bg="brandMint.500"
            color="brandDark.500"
            size="sm"
            name={user.full_name}
          />
        </MenuButton>
        <MenuList p={0}>
          <MenuItem>Download</MenuItem>
          <MenuItem>Create a Copy</MenuItem>
          <MenuItem>Mark as Draft</MenuItem>
          <MenuItem>Delete</MenuItem>
          <MenuItem>Attend a Workshop</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default TopBar;
