import i18next from "i18next";

export const SETTINGS_OPTIONS = [
  {
    title: "Account",
    iconPath: "fi-rr-circle-user",
  },
  {
    title: "General",
    iconPath: "fi-rr-settings",
  },
  {
    title: "Privacy",
    iconPath: "fi-rr-lock",
  },
  {
    title: "Notifications",
    iconPath: "fi-rr-bell",
  },
  {
    title: "Language",
    iconPath: "fi-rr-language",
    options: [
      {
        title: "English",
        value: "en",
        onClick: () => i18next.changeLanguage("en"),
      },
      {
        title: "Polski",
        value: "pl",
        onClick: () => i18next.changeLanguage("pl"),
      },
    ],
  },
  {
    title: "Help",
    iconPath: "fi-rr-interrogation",
  },
  {
    title: "Logout",
    iconPath: "fi-rs-sign-out-alt",
    onClick: () => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      window.location.assign("/");
    },
  },
];
