import * as React from "react";
import {
  ChakraProvider as ChakraContainer,
  extendTheme,
  theme as defaultTheme,
} from "@chakra-ui/react";

export const ChakraProvider = ({ children }: any) => {
  const theme = extendTheme({
    colors: {
      brand: {
        200: "#FFCE66",
        500: "#ef672d",
      },
      brandMint: {
        500: "#A9E5D5",
      },
      brandDark: {
        500: "#44455B",
      },
    },
    components: {
      Button: {
        baseStyle: {
          _hover: {},
        },
      },
    },
  });

  return <ChakraContainer theme={theme}>{children}</ChakraContainer>;
};
