import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
  element: JSX.Element;
  user?: any;
}

export const PrivateRoute = ({
  element: RouteComponent,
  user,
}: PrivateRouteProps): JSX.Element => {
  const isAuthenticated = localStorage.getItem("accessToken");
  if (isAuthenticated) {
    return RouteComponent;
  }
  return <Navigate to="/auth" replace />;
};
