import { Divider, Flex, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type SettingOptionProps = {
  iconPath: string;
  options: null | { title: string; onClick: () => void; value: string }[];
  title: string;
  onClick?: () => any;
};

const SettingOption = ({
  iconPath,
  title,
  onClick,
  options,
}: SettingOptionProps) => {
  const { i18n } = useTranslation();
  const [isSettingMenuOpened, setIsSettingMenuOpened] =
    useState<boolean>(false);

  return (
    <>
      <Flex
        onClick={() => {
          setIsSettingMenuOpened((prev) => !prev);
          if (typeof onClick === "function") onClick();
        }}
        w="80%"
        margin="0 auto"
        alignItems="start"
        padding="20px 0"
        gap="15px"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex w="100%" justifyContent="space-between">
          <Flex alignItems="center" gap="15px">
            <i className={`fi ${iconPath}`}></i>
            <Text>{title}</Text>
          </Flex>
          <Flex>
            <i
              className={`fi fi-rs-angle-small-${
                isSettingMenuOpened ? "down" : "right"
              }`}
            ></i>
          </Flex>
        </Flex>
        {options && isSettingMenuOpened && (
          <Flex minH="100px" w="100%" flexWrap="wrap">
            <RadioGroup w="100%" value={i18n.language}>
              {options.map(({ title, onClick, value }, index) => {
                return (
                  <>
                    <Flex
                      py="20px"
                      w="100%"
                      pl="25px"
                      alignContent="center"
                      alignItems="center"
                      onClick={onClick}
                    >
                      <Radio colorScheme="orange" value={value}>
                        {title}
                      </Radio>
                    </Flex>
                    {index + 1 !== options.length && (
                      <Divider w="80%" ml="40px" />
                    )}
                  </>
                );
              })}
            </RadioGroup>
          </Flex>
        )}
      </Flex>
      <Divider w="80%" margin="0 auto" />
    </>
  );
};

export default SettingOption;
