import { AiOutlinePlus } from "react-icons/ai";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { AppContext } from "../../../app/contexts/appState.context";

type PlusButtonMenuProps = {
  label?: string;
  onClickCallback?: () => any;
  customIcon?: any;
};

const PlusButtonMenu = ({
  label,
  onClickCallback = () => {},
  customIcon,
}: PlusButtonMenuProps) => {
  const { appState } = useContext(AppContext);

  return (
    <Flex
      position="fixed"
      bottom="38px"
      left="50%"
      transform="translateX(-50%)"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      flexWrap="wrap"
      gap={4}
      zIndex={1}
    >
      <Button
        bg="brand.500"
        _hover={{ bg: "brand.500" }}
        w="50px"
        h="50px"
        borderRadius="50%"
        color="white"
        fontSize="24px"
        onClick={appState.callback ?? onClickCallback}
      >
        {appState.icon ?? customIcon ?? <AiOutlinePlus size={24} />}
      </Button>
      <Text w="100%" textAlign="center">
        {label}
      </Text>
    </Flex>
  );
};

export default PlusButtonMenu;
