import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../../app/contexts/user.context";
import { Flex, Spinner } from "@chakra-ui/react";
import { endShoppingListInvitation } from "../../shared/api/invitation/invitation.api";
import { useRecoilState } from "recoil";
import { UserInfoAtom } from "../../atoms";

function SharedListActivate() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(UserInfoAtom);

  useEffect(() => {
    const getSharedDocument = async () => {
      try {
        const idOfDocument = searchParams.get("id") ?? "";
        await endShoppingListInvitation({ invitationID: idOfDocument });
      } catch (error) {
        console.log(error);
      }
    };
    const isTokenExist = localStorage.getItem("accessToken");

    if (searchParams.get("id") && isTokenExist) {
      // User has account and list id is given
      getSharedDocument();
      navigate("/");
    } else if (searchParams.get("id") && !isTokenExist) {
      // List id is given but user has not have an account
      localStorage.setItem(
        "redirect",
        "/invite-accept?id=" + searchParams.get("id")
      );
      navigate("/auth");
    } else {
      navigate("/");
    }
  }, [searchParams, user, navigate]);

  return (
    <Flex w="100%" minH="500px" alignItems="center" justifyContent="center">
      <Spinner />
    </Flex>
  );
}

export default SharedListActivate;
