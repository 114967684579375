import { MdAddReaction, MdEdit } from "react-icons/md";
import { GRAY_ICON_COLOR } from "../../../../variables/colors.variables";
import { HOME_PATH } from "../../../../variables/paths.variables";
import { Button, Flex } from "@chakra-ui/react";
import { createShoppingListInvitation } from "../../../../api/invitation/invitation.api";

type HeaderOptionsProps = {
  setIsEditListModeOn: any;
  shoppingListDocumentReference: any;
};

const HeaderOptions = ({
  setIsEditListModeOn,
  shoppingListDocumentReference,
}: HeaderOptionsProps) => {
  const onShareListClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      const response = await createShoppingListInvitation({
        listReference: shoppingListDocumentReference,
      });

      const shareData = {
        title: "Carttie",
        text: "Hi! Join to my shopping list",
        url: `${HOME_PATH}invite-accept/?id=${response.data._id}`,
      };

      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        console.log(shareData.url); // Display link in console when there is no https connection (localhost)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsEditListModeOn(true);
  };

  return (
    <Flex h="16px" position="absolute" right="10px" top="15px">
      <Button bg="none" p="0px" onClick={onShareListClick}>
        <MdAddReaction size={16} color={GRAY_ICON_COLOR} />
      </Button>
      <Button bg="none" p="0px" onClick={onEditClick}>
        <MdEdit size={16} color={GRAY_ICON_COLOR} />
      </Button>
    </Flex>
  );
};

export default HeaderOptions;
