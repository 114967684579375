import { Outlet } from "react-router-dom";
import BottomNavigation from "../../../shared/components/bottom-navigation";
import TopBar from "../../../shared/components/top-bar";

export const SettingsLayout = () => {
  return (
    <>
      <TopBar />
      <Outlet />
      <BottomNavigation />
    </>
  );
};
