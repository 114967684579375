import { Flex, Image } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import logo from "./../../../assets/logo.png";
import OnboardingImage from "../../../assets/onboarding.svg";

export const AuthLayout = () => {
  return (
    <Flex
      gap="10px"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      mt="20px"
    >
      <Flex w="100%">
        <Image width="50px" src={logo} ml="15px" alt="logo" />
      </Flex>
      <Flex
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <Image
          src={OnboardingImage}
          alt="OnboardingImage"
          mt="40px"
          w="250px"
        />
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          gap="30px"
          w="100%"
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            w="80%"
            flexWrap="wrap"
            gap="5px"
          >
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
