import { ProductInListDTO } from "../../../../api/productInList/product-in-list.api.type";
import { getProductByID } from "../../../../api/products.api";
import { ProductsDTO } from "../../../../api/products.api.type";

type ProductsGroupedByCategoryType = {
  [key: string]: ProductInListDTO[];
};

export const prepProductsToDisplay = (
  productsInShoppingList: ProductInListDTO[] | null,
  products: ProductsDTO[]
): {
  countOfProductsLeft: number;
  checkedOutProducts: ProductsGroupedByCategoryType;
  productsLeft: ProductsGroupedByCategoryType;
} => {
  if (!productsInShoppingList)
    return { countOfProductsLeft: 0, checkedOutProducts: {}, productsLeft: {} };

  const checkedOutProducts: ProductsGroupedByCategoryType = {};
  const productsLeft: ProductsGroupedByCategoryType = {};
  let countOfProductsLeft = 0;
  // Group products by checkout status and by category
  productsInShoppingList.forEach(async (product: ProductInListDTO) => {
    let productToUse = products.find(
      (defProduct) => product.productReference === defProduct.id
    );

    if (!product.isChecked) {
      countOfProductsLeft = countOfProductsLeft + 1;
    }

    if (!productToUse) return;

    const productType = productToUse.type;

    if (!product.isChecked) {
      if (!productsLeft[productType]) {
        productsLeft[productType] = [product];
      } else {
        productsLeft[productType].push(product);
      }
      return;
    }

    if (!checkedOutProducts[productType]) {
      checkedOutProducts[productType] = [product];
    } else {
      checkedOutProducts[productType].push(product);
    }
  });

  // Sort result by category in alphabetic order
  const sortedProductsLeft = Object.keys(productsLeft)
    .sort()
    .reduce((obj: ProductsGroupedByCategoryType, key: string) => {
      obj[key] = productsLeft[key];
      return obj;
    }, {});
  const sortedCheckOutProducts = Object.keys(checkedOutProducts)
    .sort()
    .reduce((obj: ProductsGroupedByCategoryType, key: string) => {
      obj[key] = checkedOutProducts[key];
      return obj;
    }, {});

  return {
    countOfProductsLeft,
    checkedOutProducts: sortedCheckOutProducts,
    productsLeft: sortedProductsLeft,
  };
};
